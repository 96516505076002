









import Vue from 'vue'
import { ADS_MANAGER_PERMISSION, RULES_PERMISSION, INTERESTS_PERMISSION } from '@/constants/UserPermissions'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'YourTrialHasExpired',
  computed: {
    ...mapGetters('profile', ['getDiscardedPermission']),
    description_text () {
      switch (this.getDiscardedPermission) {
        case ADS_MANAGER_PERMISSION:
          return [
            'You can create campaigns only with an active subscription.',
            'To continue managing your campaigns with AutomatedRules please upgrade to a paid plan.'
          ]
        case RULES_PERMISSION:
          return [
            'You can create automated rules only with an active subscription.',
            'To continue automating your campaigns with AutomatedRules rules please upgrade to a paid plan.'
          ]
        case INTERESTS_PERMISSION:
          return [
            'You can create interest-based audiences only with an active subscription.',
            'To continue using Interest Explorer with AutomatedRules please upgrade to a paid plan.'
          ]
        default:
          return [
            'You can create campaigns only with an active subscription.',
            'To continue managing your campaigns with AutomatedRules please upgrade to a paid plan.'
          ]
      }
    }
  },
  methods: {
    go_to_profile () {
      this.$router.push({ name: 'Profile' })
    }
  }
})
